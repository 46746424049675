import React from 'react';
import Layout from "../components/layouts";
import Reveal from 'react-reveal';
import ebr1 from "../images/ebr-1.png";
import ebr2 from "../images/ebr-2.png";
import ebr3 from "../images/ebr-3.png";
import ebr4 from "../images/ebr-4.png";
import ebr5 from "../images/ebr-5.png";
import ebr6 from "../images/ebr-6.png";
import ebr7 from "../images/ebr-7.png";
import ebr8 from "../images/ebr-8.png";
import ebr9 from "../images/ebr-9.png";
import ebr10 from "../images/ebr-10.png";
import ebr11 from "../images/ebr-11.png";
import ebr12 from "../images/ebr-12.png";
const BattleRoyal = () => {
  return (
    <Layout navBg>
    <div className="ebr-page">
        <div className="ebr-header ebr"></div>
        <div className="ebr-inner">
          <div className="ebr-description">
              <p>Energy Battle Royal. Earth's Mightiest Energy Sources Battle For Who Will Power the World?</p>
              <p style={{ marginTop: 40 }}><strong>Recent Events:</strong></p>
              <h1>Energy Battle Royal At Future Green Leaders Summit</h1>
              <div className="embed-container" >
                <iframe src="https://www.youtube.com/embed/j7ONZzOo-MY" />
              </div>
              <p><a href="https://www.flickr.com/photos/74696088@N00/albums/72177720306610275" target="_blank">Check out the album here!</a></p>
              <p>On March 15th, 2023  Global Inheritance, LAUSD and SoCalRen presented the Energy Battle Royal at the Future Green Leaders Summit at the LA Convention Center.</p>
              <p>The Energy Battle Royal features every major renewable energy source including human power! LAUSD students powered up the sound system + LED light towers by generating kinetic energy on the engineered seesaws, human-sized hamster wheels, bikes, and a swing set. After running, jumping and swinging the energy wells were full, the dance-off began!</p>
              <p>All student participants received one of six “Energy Trading Cards” to assign them to a team (Team Solar, Team Geothermal, Team Hydrogen, Team Biogas, Team Ethanol and Team Wind.). The Energy Sources and MC Powerful communicated the pros and cons of each power source while professional dancers showed the students original dance moves representing each Energy Source. Multiple sources scored victories on the dance floor. The students found out quickly that every Renewable Energy Source has great abilities and we need to support them all.</p>
              <p>The Future Green Leaders Summit is a day of innovative educational programming giving 1,800 middle schoolers from South LA the opportunity to explore different career pathways in sustainability and energy efficiency.</p>
              <p>For more information or questions, contact Global Inheritance. info@globalinheritance.org</p>
          </div>
      </div>
        
        
      <div className="ebr-inner" style={{marginTop: 50}}>
          <Reveal effect="animated fadeIn">
            <div className="ebr-gallery">
              <div className="ebr-ex">
                <img src={ebr1} />
              </div>
              <div className="ebr-ex">
                <img src={ebr2} />
              </div>
              <div className="ebr-ex">
                <img src={ebr3} />
              </div>
              <div className="ebr-ex">
                <img src={ebr4} />
              </div>
              <div className="ebr-ex">
                <img src={ebr5} />
              </div>
              <div className="ebr-ex">
                <img src={ebr6} />
              </div>
              <div className="ebr-ex">
                <img src={ebr7} />
              </div>
              <div className="ebr-ex">
                <img src={ebr8} />
              </div>
              <div className="ebr-ex">
                <img src={ebr9} />
              </div>
              <div className="ebr-ex">
                <img src={ebr10} />
              </div>
              <div className="ebr-ex">
                <img src={ebr11} />
              </div>
              <div className="ebr-ex">
                <img src={ebr12} />
              </div>
            </div>
          </Reveal>
        </div>
    </div>
    </Layout>
);
}

export default BattleRoyal;


